import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RegistSpecImage } from "../../../pages/admin/dashboard/regist/regist-specImage";
export interface Props {data : any, specType:string, setSpecInfo:Function, getList : Function}
export const SpecLeftList:React.FC<Props> = ({data, specType, setSpecInfo}) =>{
    const {type, typeId} : any = useParams()
    const location = useLocation()
    const subType : any = new URLSearchParams(location.search).get('type') || 0;
    const navigate = useNavigate()
    const keyword : any = new URLSearchParams(location.search).get('keyword') || ''
    const order : any = new URLSearchParams(location.search).get('order') || 'asc'
    const [specImageInfo, setSpecImageInfo] = useState<any>({})
    const handleFilter = () => {
        navigate(`${location.pathname}?type=${subType}&keyword=${keyword}&column=specName&order=${order === 'asc' ? 'desc' : 'asc'}`)
    }
    const pathname = location.pathname===`/admin/specification/productSpec/${typeId}` || location.pathname===`/admin/specification/remark/${typeId}`;
    const handleSelectType = (list : any) => {
        if(location.pathname===`/admin/specification/productSpec/${typeId}` || location.pathname===`/admin/specification/remark/${typeId}`){
            navigate(`/admin/specification/${type}/${list?.specIdx}?type=${list?.specSubType || ''}&keyword=${keyword}&order=${order}`)
        } else {
            navigate(`/admin/specification/remark/${list?.specIdx}?type=${list?.specSubType || ''}`)
        }
    }
    // typeId 가 변경될 때마다 해당 이미지 상태값을 즉시 가져오도록 설정
    useLayoutEffect(()=>{
        const findItem = data?.find((item : any) => item.specIdx === parseInt(typeId));
        if(findItem){setSpecInfo((prev:any)=>({...prev, imageStatus : findItem?.specImageStatus}))}
    }, [data, typeId])

    return(
        <>
        <thead>
            <tr>
                <th>
                    <div>
                        <span className="sortWrap" onClick={handleFilter}>
                            Type
                            <span className={`sort ${order === 'desc' ? 'desc' : 'asc'}`}></span>
                        </span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            {data?.map((list:any, index : number) => (
            <tr key={index}>
                <td className={list?.specIdx === parseInt(typeId) && parseInt(list?.specSubType) === parseInt(subType) ? 'active' : ''} onClick={() => handleSelectType(list)}>
                    <div>
                        {list?.specName}
                        <button onClick={() => setSpecInfo((prev: any) => ({...prev, isActive: true}))}><i className="fa-regular fa-plus"></i></button>
                    </div>
                </td>
            </tr>
                ))}
        </tbody>
        </>
    )
}
